import Dropdown from "@components/DropDown";
import { FloatingPortal, useFloating } from "@floating-ui/react";
import { Combobox } from "@headlessui/react";
import {
  WorkflowKeywords,
  getKeywordsQuery,
} from "@screens/create-policy/queries";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { classNames } from "src/utils/utils";

export const PredictorSelect = ({
    showError,
    value,
    setValue,
    isWorkflowEditable,
    variableID,
  }: {
    showError: boolean;
    value?: string;
    isWorkflowEditable:boolean;
    setValue: (value: {
      dependsOn: number;
      value: string;
      dataSourceType: number;
      id: number;
    }) => void;
    variableID: number;
  }) => {
    const { workflowId, nodeId } = useParams();
  
    const keywordsQuery = useQuery(
      getKeywordsQuery<"workflow">({
        id: workflowId || "",
        ruleGroupID: nodeId,
        type: "workflow",
      })
    );
  
    let source = "",
      pred = "";
    if (value) {
      let rest;
      [source, ...rest] = value.split(".");
      if (rest.length === 0) {
        source = "custom";
        pred = value;
      } else pred = rest.join(".");
    }
  
    const [selectedSource, setSelectedSource] = useState(source);
    const [selectedPred, setSelectedPred] = useState(pred);
    const [query, setQuery] = useState(pred);
  
    const { x, y, strategy, refs } = useFloating();
  
    const {
      sourceList,
      modelNames,
      modelsetNames,
      decisionTableNames,
      rulesetNames,
      keywords,
    } = useMemo(() => {
      const keywords =
        keywordsQuery.data?.data ?? ({} as Partial<WorkflowKeywords>);
  
      const sourceList = Object.keys(
        keywordsQuery.data?.data.predictorsList ?? {}
      );
      let modelNames: string[] = [],
        rulesetNames: string[] = [],
        decisionTableNames: string[] = [],
        modelsetNames: string[] = [];
  
      if (keywords.modelExprs)
        modelNames = keywords.modelExprs.map((i) => i.name);
  
      if (keywords.modelSets)
        modelsetNames = keywords.modelSets.map((i) => i.name);
  
      if (keywords.modelDecisionTable)
        decisionTableNames = keywords.modelDecisionTable.map((i) => i.name);
  
      if (keywords.rulesets) rulesetNames = keywords.rulesets.map((i) => i.name);
  
      sourceList.push(
        ...modelNames,
        ...rulesetNames,
        ...decisionTableNames,
        ...modelsetNames
      );
      return {
        sourceList,
        modelNames,
        rulesetNames,
        decisionTableNames,
        modelsetNames,
        keywords,
      };
    }, [keywordsQuery.data]);
  
    const getSourceDataType = (text: string) => {
      if (text === "custom") {
        return 0;
      } //static
      else if (text === "input") return 2;
      // TODO: Need to check these two on the repsonse from sourceList api.
      else if (Object.keys(keywords?.predictorsList || {}).includes(text))
        return 1;
      else if (Object.keys(keywords?.customDataSources || {}).includes(text))
        return 3; //custom datasource type
      else if (
        modelsetNames.includes(text) ||
        modelNames.includes(text) ||
        rulesetNames.includes(text) ||
        decisionTableNames.includes(text)
      )
        return 4;
      else return -1;
    };
  
    const handleChange = (
      selectedPred: string,
      selectedSource: string,
      query: string
    ) => {
      let dataSourceType = getSourceDataType(selectedSource);
  
      if (dataSourceType === 0) {
        setValue({
          value: query,
          dataSourceType,
          dependsOn: -1,
          id: variableID ?? -1,
        });
      } else if (dataSourceType === 1) {
        setValue({
          id: variableID ?? -1,
          value: selectedSource + "." + selectedPred,
          dataSourceType,
          dependsOn: keywords?.predictorsList?.[selectedSource].id || -1,
        });
      } else if (dataSourceType === 2) {
        setValue({
          value: selectedSource + "." + selectedPred,
          dataSourceType,
          dependsOn: -1,
          id: variableID ?? -1,
        });
      } else if (dataSourceType === 3) {
        setValue({
          id: variableID ?? -1,
          value: selectedSource + "." + selectedPred,
          dataSourceType,
          dependsOn: keywords?.customDataSources?.[selectedSource].id || -1,
        });
      } else if (dataSourceType === 4) {
        setValue({
          id: variableID ?? -1,
          value: selectedSource + "." + selectedPred,
          dataSourceType,
          dependsOn: -1,
        });
      }
    };
  
    // TODO: need better solution for this.
    useEffect(() => {
      if (value) {
        let source = "",
          pred = "";
        if (value) {
          let rest;
          [source, ...rest] = value.split(".");
          pred = rest.join(".");
          if (rest.length === 0) {
            pred = value;
            source = "custom";
          }
        }
  
        handleChange(pred, source, value);
      }
      //   eslint-disable-next-line
    }, [value]);
  
    const getOptions = () => {
      if (keywords?.predictorsList?.[selectedSource])
        return keywords?.predictorsList?.[selectedSource].list
          ?.filter((e) => e.toLowerCase().includes(query?.toLowerCase()))
          .slice(0, 100)
          ?.map((p) => (
            <Combobox.Option
              key={p}
              value={p}
              className="px-4 py-2 text-sm text-neutral-500 cursor-pointer hover:bg-neutral-25"
            >
              {p}
            </Combobox.Option>
          ));
      if (keywords?.customDataSources?.[selectedSource])
        return keywords?.customDataSources?.[selectedSource].list
          ?.filter((e) => e.toLowerCase().includes(query?.toLowerCase()))
          ?.map((p) => (
            <Combobox.Option
              key={p}
              value={p}
              className="px-4 py-2 text-sm text-neutral-500 cursor-pointer hover:bg-neutral-25"
            >
              {p}
            </Combobox.Option>
          ));
  
      if (modelsetNames.includes(selectedSource))
        return keywords?.modelSets
          ?.find((i) => i.name === selectedSource)
          ?.output?.filter((e) => e.toLowerCase().includes(query?.toLowerCase()))
          ?.map((p) => (
            <Combobox.Option
              key={p}
              value={p}
              className="px-4 py-2 text-sm text-neutral-500 cursor-pointer hover:bg-neutral-25"
            >
              {p}
            </Combobox.Option>
          ));
  
      if (modelNames.includes(selectedSource))
        return keywords?.modelExprs
          ?.find((i) => i.name === selectedSource)
          ?.output?.filter((e) => e.toLowerCase().includes(query?.toLowerCase()))
          ?.map((p) => (
            <Combobox.Option
              key={p}
              value={p}
              className="px-4 py-2 text-sm text-neutral-500 cursor-pointer hover:bg-neutral-25"
            >
              {p}
            </Combobox.Option>
          ));
  
      if (decisionTableNames.includes(selectedSource))
        return keywords?.modelDecisionTable
          ?.find((i) => i.name === selectedSource)
          ?.output?.filter((e) => e.toLowerCase().includes(query?.toLowerCase()))
          ?.map((p) => (
            <Combobox.Option
              key={p}
              value={p}
              className="px-4 py-2 text-sm text-neutral-500 cursor-pointer hover:bg-neutral-25"
            >
              {p}
            </Combobox.Option>
          ));
  
      if (rulesetNames.includes(selectedSource))
        return keywords?.rulesets
          ?.find((i) => i.name === selectedSource)
          ?.output?.filter((e) => e.toLowerCase().includes(query?.toLowerCase()))
          ?.map((p) => (
            <Combobox.Option
              key={p}
              value={p}
              className="px-4 py-2 text-sm text-neutral-500 cursor-pointer hover:bg-neutral-25"
            >
              {p}
            </Combobox.Option>
          ));
    };
  
    return (
      <div className="flex h-full gap-0.5">
        <Dropdown
          defaultValue={source}
          disabled={!isWorkflowEditable}
          onChange={(e: string) => {
            setSelectedSource(e);
            setSelectedPred("");
            handleChange(selectedPred, selectedSource, query);
          }}
        >
          <Dropdown.Button
            className={classNames(
              "h-[42px] mt-[3px] rounded-r-none min-w-[9rem] text-sm",
              !source && showError && "border !border-error-500"
            )}
          >
            {selectedSource || "Select source"}
          </Dropdown.Button>
          <Dropdown.Options>
            {sourceList.map((s) => (
              <Dropdown.Option value={s} key={s}>
                {s}
              </Dropdown.Option>
            ))}
            <Dropdown.Option value="custom">custom</Dropdown.Option>
          </Dropdown.Options>
        </Dropdown>
        <div>
          <Combobox
            value={selectedSource === "custom" ? query : selectedPred}
            disabled={!isWorkflowEditable}
            onChange={(e) => {
              setSelectedPred(e);
              handleChange(e, selectedSource, query);
            }}
          >
            <Combobox.Input
              defaultValue={pred}
              onChange={(e) => {
                setQuery(e.target.value);
                handleChange(selectedPred, selectedSource, e.target.value);
              }}
              className={classNames(
                "border font-b1-medium border-neutral-100 rounded-r-md -translate-y-[1px] text-neutral-black",
                showError &&
                  !(selectedSource === "custom" ? query : selectedPred) &&
                  "!border-error-500"
              )}
              ref={refs.setReference}
            />
            <br />
            <FloatingPortal>
              <Combobox.Options
                ref={refs.setFloating}
                style={{
                  width: "max-content",
                  position: strategy,
                  top: y ?? 0,
                  left: x ?? 0,
                  zIndex: 9999,
                }}
                className="max-h-44 overflow-scroll rounded-md bg-white shadow ring-1 ring-gray-100"
              >
                {getOptions()}
              </Combobox.Options>
            </FloatingPortal>
          </Combobox>
        </div>
      </div>
    );
  };
  