import chevronDown from "@assets/icons/chevron-down.svg";
import { Combobox } from "@headlessui/react";
import useOnClickOutside from "@hooks/useOnClickOutside";
import useKeywordsFromWorkflowKeywords from "@screens/workflow/studio/hooks/useKeywordsFromWorkflowKeywords";
import { classNames, notify, weightedSearch } from "@utils/utils";
import { memo, useRef, useState } from "react";
import { FixedSizeList } from "react-window";

type Props = {
  data: string;
  tableName: string;
  colIndex:number;
  value:string;
  handleColumnChange:(name:string, colIndex:number) => void;
  setIsEditing: () => void;
  isEditing: boolean;
  modelNames?: string[];
  keywords: ReturnType<typeof useKeywordsFromWorkflowKeywords>;
  headersList: { name: string }[];
};

const MatrixHeader = ({
  value,
  handleColumnChange,
  colIndex,
  setIsEditing,
  tableName,
  isEditing,
  keywords,
  modelNames,
  headersList,
}: Props) => {
  const [searchQuery, setSearchQuery] = useState("");

  let keywordsList: { name: string; source: string }[] = [];

  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useOnClickOutside(
    ref,
    () => {
      setSearchQuery("");
      setIsOpen(false);
    },
    (e) => {
      if (
        !(e.target instanceof HTMLElement) ||
        !e.target.dataset.dropdownOption
      )
        return false;
      return e.target.dataset.dropdownOption === "yes";
    }
  );

  // if (keywords.rulesets)
  //   keywordsList.push(
  //     ...keywords.rulesets
  //       .map((ruleset) => {
  //         return ruleset.output.map((item) => ({
  //           name: `${ruleset.name}.${item}`,
  //           source: ruleset.name,
  //           type: "ruleset",
  //         }));
  //       })
  //       .flat()
  //   );
  //
  // if (keywords.predictorsList)
  //   keywordsList.push(
  //     ...Object.keys(keywords.predictorsList)
  //       .map((source) => {
  //         return keywords.predictorsList[source].map((item) => ({
  //           name: `${source}.${item}`,
  //           type: "predictor",
  //           source: source,
  //         }));
  //       })
  //       .flat()
  //   );
  //
  // if (keywords.customDataSources)
  //   keywordsList.push(
  //     ...Object.keys(keywords.customDataSources)
  //       .map((source) => {
  //         return keywords.customDataSources[source].map((item) => ({
  //           name: `${source}.${item}`,
  //           type: "predictor",
  //           source: source,
  //         }));
  //       })
  //       .flat()
  //   );

  if (keywords.policies)
    keywordsList.push(
      ...Object.entries(keywords.policies)
        .map(([policy, output]) => {
          return output.map((item) => ({
            name: `policies['${policy}'].${item}`,
            type: "policy",
            source: policy,
          }));
        })
        .flat()
    );

  if (keywords.workflows)
    keywordsList.push(
      ...Object.entries(keywords.workflows)
        .map(([workflow, output]) => {
          return output.map((item) => ({
            name: `workflows['${workflow}'].${item}`,
            source: workflow,
            type: "workflow",
          }));
        })
        .flat()
    );

  if (keywords.inputs && keywords.inputs.length > 0) {
    keywordsList.push(
      ...keywords.inputs.map((inp) => {
        return {
          name: `input.${inp}`,
          source: "input",
          type: "input",
        };
      })
    );
  }

  // if (keywords.modelDecisionTable)
  //   keywordsList.push(
  //     ...keywords.modelDecisionTable
  //       .filter((item) => item.name !== tableName)
  //       .map((model) => {
  //         return model.output.map((item) => ({
  //           name: `${model.name}.${item}`,
  //           type: "decisionTable",
  //           source: model.name,
  //         }));
  //       })
  //       .flat()
  //   );
  //
  // if (keywords.modelExprs)
  //   keywordsList.push(
  //     ...keywords.modelExprs
  //       .map((model) => {
  //         return model.output?.map((item) => ({
  //           name: `${model.name}.${item}`,
  //           type: "model",
  //           source: model.name,
  //         }));
  //       })
  //       .flat()
  //   );

  keywordsList.push(
    ...Object.keys(keywords?.sources ?? {})
      // .filter((m) => m.id !== nodeId)
      .map((model) => {
        return (keywords?.sources?.[model] ?? []).map((item) => ({
          name: `${model}.${item}`,
          source: model,
        }));
      })
      .flat()
  );

  if (modelNames) {
    keywordsList.push(
      ...modelNames.map((m) => {
        return { name: m, type: "model", source: tableName };
      })
    );
  }

  keywordsList = weightedSearch(
    keywordsList,
    searchQuery,
    (item, lowercaseQuery) => {
      let score = 0;
      if (item.name.toLowerCase().startsWith(lowercaseQuery)) {
        score = 100;
      } else if (item.name.toLowerCase().includes(lowercaseQuery)) {
        score = 50;
      }
      return score;
    }
  );

    return !isEditing ? (
          <div
            ref={ref}
            className={classNames(
              "bg-neutral-0 overflow-visible font-medium first:rounded-tl-md py-1 px-4 border-r border-b border-neutral-100 h-10 flex items-center w-full"
            )}
            onClick={() => setIsEditing()}
          >
            <span className="group max-w-[calc(100%-1ch)] truncate inline-block">
              {value && (
                <span className="group-hover:block hidden absolute top-full left-0 p-1 rounded-md bg-white shadow">
                  {value}
                </span>
              )}
              {value ? value : <>&nbsp;</>}
            </span>
          </div>
        ) : (
          <Combobox
            value={keywordsList.find(() => value)}
            onChange={(e) => {
              if (!headersList.some((header) => header.name === e.name)) {
                handleColumnChange(e.name, colIndex);
                setSearchQuery(e.name);
                setIsOpen(false);
              } else {
                notify({
                  title: "Duplicate column not allowed",
                  text: "Column name already present",
                });
              }
            }}
          >
            <div ref={ref} className="relative w-full">
              <Combobox.Input
                autoFocus={false}
                placeholder="Select"
                autoComplete="off"
                onChange={(event) => setSearchQuery(event.target.value)}
                className="w-full rounded-md border focus:border-primary-300 h-10 focus:ring-0 border-neutral-100 font-b2-medium bg-white py-1.5 pl-3 pr-10 text-neutral-black"
                value={searchQuery}
                displayValue={() => value}
                onClick={() => setIsOpen(true)}
              />
              <img
                src={chevronDown}
                className={classNames(
                  "absolute right-2 top-1/2 -translate-y-1/2 w-4 h-4",
                  isOpen && "rotate-180"
                )}
                aria-hidden="true"
                alt=""
              />
              {isOpen && (
                <Combobox.Options
                  static
                  style={{
                    width: "auto",
                    minWidth: "250px",
                    position: "fixed",
                    top: ref.current
                      ? ref.current.getBoundingClientRect().top +
                        ref.current.getBoundingClientRect().height +
                        10
                      : 100,
                    left: ref.current
                      ? ref.current.getBoundingClientRect().left
                      : 400,
                  }}
                  className="absolute z-[1000] -translate-y-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 font-b2 border border-neutral-100"
                >
                  <FixedSizeList
                    itemSize={32}
                    height={keywordsList.length !== 0 ? 240 : 0}
                    itemCount={keywordsList.length}
                    width="100%"
                    overscanCount={5}
                    itemData={keywordsList}
                  >
                    {(props) => {
                      return (
                        <Combobox.Option
                          data-dropdown-option="yes"
                          className="relative w-full flex cursor-pointer select-none py-2 px-3 hover:bg-neutral-25 bg-white"
                          key={props.data[props.index].name}
                          value={props.data[props.index]}
                          style={props.style}
                        >
                          <span
                            data-dropdown-option="yes"
                            className="w-full truncate"
                          >
                            {props.data[props.index].name}
                          </span>
                        </Combobox.Option>
                      );
                    }}
                  </FixedSizeList>
                  {keywordsList.length === 0 && (
                    <Combobox.Option
                      data-dropdown-option="yes"
                      className="relative cursor-not-allowed opacity-50 select-none py-2 pl-3 pr-9 hover:bg-neutral-25 bg-white"
                      key="empty"
                      value="empty"
                      disabled
                    >
                      No Options
                    </Combobox.Option>
                  )}
                </Combobox.Options>
              )}
            </div>
          </Combobox>
        );
};

export default memo(MatrixHeader);
