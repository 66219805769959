import { ChangeEvent, useRef } from "react";
import { useReactFlow } from "reactflow";
import { useWorkflowContext } from "@screens/workflow/WorkflowContext";
import {
  BE_BRANCH_NODE_TYPE,
  BE_MODEL_SET_NODE_TYPE,
  BE_RULE_SET_NODE_TYPE,
  BRANCH_NODE_TYPE,
  MODEL_NODE_TYPE,
  MODEL_SET_NODE_TYPE,
  RULE_SET_NODE_TYPE,
} from "@screens/workflow/config";
import { getWorkflowQuery, useImportNode } from "@screens/workflow/queries";
import { getBranchConditionsQuery } from "@screens/workflow/studio/components/Branch/queries";
import { getModelExpressionQuery } from "@screens/workflow/studio/components/Model/queries";
import { getModelSetItemsQuery } from "@screens/workflow/studio/components/ModelSet/queries";
import { getRuleSetConditionsQuery } from "@screens/workflow/studio/components/RuleSet/queries";
import { getNewNodeName } from "@screens/workflow/studio/utils";
import { ExportNodeType, NodeImportData } from "@screens/workflow/types";
import { useQueryClient } from "@tanstack/react-query";
import { notify } from "@utils/utils";

export default function useImportNodeWithInput(
  nodeId?: string,
  nodeType?: string
) {
  const importNode = useImportNode();
  const { workflow } = useWorkflowContext();
  const { getNodes, setCenter } = useReactFlow();
  const inputRef = useRef<HTMLInputElement>(null);
  const qc = useQueryClient();

  const getNodeOrName = (
    type:
      | "workflowNode"
      | "policyNode"
      | "branchNode"
      | "ruleSet"
      | "sourceNode"
      | "modelExpressionNode"
      | "matrixNode"
      | "modelSet"
  ) => {
    if (nodeId) return { nodeId };
    return { name: getNewNodeName(type, getNodes()) };
  };

  const importNodeFn = () => {
    inputRef.current?.click();
  };

  const inputOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;
    processImportedFile(file);
    e.target.value = '';
  };

  const processImportedFile = (
    file: File,
    options?: { globalImport: boolean }
  ) => {
    const reader = new FileReader();

    const globalImport = options?.globalImport ?? false;

    reader.addEventListener("load", () => {
      const result = reader.result as string;
      if (!result) return;
      let jsonContents: ExportNodeType;
      try {
        jsonContents = JSON.parse(result);
      } catch {
        return notify({ title: "Invalid File", text: "File is corrupted" });
      }
      if (
        nodeType &&
        nodeType === MODEL_SET_NODE_TYPE &&
        jsonContents.feType !== MODEL_NODE_TYPE &&
        nodeType !== jsonContents.feType
      )
        return notify({ title: "Invalid File", text: "Incorrect node type" });

      let data: NodeImportData;
      // @ts-ignore
      // @ts-ignore
      switch (jsonContents.feType) {
        // case MODEL_NODE_TYPE:
        //   data = {
        //     ...getNodeOrName(MODEL_NODE_TYPE),
        //     type: BE_MODEL_NODE_TYPE,
        //     expressions: jsonContents.expressions,
        //   };
        //   break;
        case RULE_SET_NODE_TYPE:
          data = {
            ...getNodeOrName(RULE_SET_NODE_TYPE),
            type: BE_RULE_SET_NODE_TYPE,
            rulesets: jsonContents.rulesets,
          };
          break;
        case BRANCH_NODE_TYPE:
          data = {
            ...getNodeOrName(BRANCH_NODE_TYPE),
            type: BE_BRANCH_NODE_TYPE,
            expressions: jsonContents.expressions,
          };
          break;
        //   We removed model node type in favour of model set,
        //   but we still need to support import of model node into modelset
        case MODEL_NODE_TYPE:
        case MODEL_SET_NODE_TYPE:
          if (jsonContents.feType === MODEL_NODE_TYPE) {
            jsonContents.expressions = jsonContents.expressions.map((item) => ({
              ...item,
              type: "expression",
            }));
          }
          data = {
            ...getNodeOrName(MODEL_SET_NODE_TYPE),
            type: BE_MODEL_SET_NODE_TYPE,
            expressions: jsonContents.expressions,
          };
          break;
      }

      importNode.mutate(
        {
          workflowId: workflow?.id,
          data,
        },
        {
          onSuccess: (data, vars) => {
            if (globalImport) {
              qc.invalidateQueries(getWorkflowQuery(vars.workflowId));
              if (!data?.data.data) return;
              setCenter(0, 0, { duration: 500 });
            } else {
              switch (nodeType) {
                case RULE_SET_NODE_TYPE:
                  qc.invalidateQueries(
                    getRuleSetConditionsQuery(workflow?.id, data?.data.data)
                  );
                  break;
                case MODEL_NODE_TYPE:
                  qc.invalidateQueries(
                    getModelExpressionQuery(vars.workflowId, data?.data.data)
                  );
                  break;
                case MODEL_SET_NODE_TYPE:
                  qc.invalidateQueries(
                    getModelSetItemsQuery(vars.workflowId, data?.data.data)
                  );
                  break;
                case BRANCH_NODE_TYPE:
                  qc.invalidateQueries(
                    getBranchConditionsQuery({
                      workflowId: vars.workflowId!,
                      branchId: data?.data.data!,
                    })
                  );
                  break;
              }
            }
          },
        }
      );
    });

    reader.readAsText(file);
  };

  return {
    importNode: importNodeFn,
    processImportedFile,
    getInputProps: () => {
      return {
        type: "file",
        ref: inputRef,
        accept: ".json",
        onChange: inputOnChange,
        className: "hidden",
      };
    },
  };
}
