const AWS_BASE_URL = "https://finbox-cdn.s3.ap-south-1.amazonaws.com/sentinel/";

export const LOG_ADD_ICON = `${AWS_BASE_URL}changeLog/log_add.svg`;
export const LOG_DELETE_ICON = `${AWS_BASE_URL}changeLog/log_deleted.svg`;
export const LOG_EDIT_ICON = `${AWS_BASE_URL}changeLog/log_updated.svg`;
export const CHECK_SQUARE_ICON = `${AWS_BASE_URL}changeLog/check_square.svg`;
export const DATAFLOW_TRANSITION = `${AWS_BASE_URL}common/dataflow_transition.svg`;
export const EXPRESSION_ADD = `${AWS_BASE_URL}changeLog/expression_add.svg`;
export const EXPRESSION_DELETE = `${AWS_BASE_URL}changeLog/expression_delete.svg`;
export const EXPRESSION_EDIT = `${AWS_BASE_URL}changeLog/expression_update.svg`;
export const NO_CHANGE_LOG = `${AWS_BASE_URL}changeLog/empty_change_log.svg`;
export const NO_LOG_GENERATED = `${AWS_BASE_URL}changeLog/no_change_log.svg`;
export const FILE_02 = `${AWS_BASE_URL}common/file_02.svg`;
export const HORIZONTAL_ARROW_COLLAPSE = `${AWS_BASE_URL}common/horizontal_arrow_collapse.svg`;
export const HORIZONTAL_ARROW_EXPAND = `${AWS_BASE_URL}common/horizontal_arrow_expand.svg`;
export const CHANGE_LOG_NO_VERSION_LIST = `${AWS_BASE_URL}changeLog/no_version_list.svg`;
export const ILLUSTRATION_PUBLISH_POLICY = `${AWS_BASE_URL}illustrations/illustration_publish_policy.webp`;
export const DOWNLOAD_ICON = `${AWS_BASE_URL}common/download_cloud_01.svg`;
export const CHEVRON_DOWN_WHITE = `${AWS_BASE_URL}common/chevron_down_white.svg`;
