import { useNavigate, useParams } from "react-router";
import { DecisionTableNode } from "../../types";
import { getExpressionTypeIcon, highlightDifferences } from "../../utils";

export const ModelSetTable = ({
  modelId,
  name,
  prevDecision,
  currDecision,
  isUpdated,
  prevName,
  state,
}: {
  modelId: string;
  name: string | undefined | null;
  prevDecision: DecisionTableNode | null | undefined;
  currDecision: DecisionTableNode | null | undefined;
  state: string;
  isUpdated?: boolean;
  prevName?: string;
}) => {
  const navigate = useNavigate();
  const { workflowId } = useParams();
  const defaultHighlight = highlightDifferences(
    prevDecision?.default || "",
    currDecision?.default || ""
  );

  const rowCountHighlight = highlightDifferences(
    prevDecision?.rowCount || "",
    currDecision?.rowCount || ""
  );

  const columnCountHighlight = highlightDifferences(
    prevDecision?.columnCount || "",
    currDecision?.columnCount || ""
  );

  return (
    <div>
      {!!isUpdated ? (
        <div className="bg-neutral-0 border-y border-neutral-100 px-4 h-[100%] py-2 font-b2-medium text-neutral-black">
          <span className="flex flex-wrap items-center gap-2">
            {getExpressionTypeIcon(state)}
            {prevName} <span className="text-neutral-500">is changed to</span>{" "}
            {name}
          </span>
        </div>
      ) : (
        <div className="bg-neutral-0 border-y border-neutral-100 px-4 h-8 py-2 font-b2-medium text-neutral-black">
          <span className="flex items-center gap-2">
            {getExpressionTypeIcon(state)}
            {name}
          </span>
        </div>
      )}
      <div className="flex">
        <div className="border-r w-[50%] flex flex-col px-4 py-3 gap-2 overflow-none">
          {prevDecision ? (
            <div className="text-neutral-500 font-b2 flex flex-wrap">
              {prevDecision?.default && (
                <span className="mr-2">
                  Default value:{" "}
                  <span className="font-code text-sm text-primary-900">
                    {defaultHighlight.prevHighlighted}
                  </span>
                </span>
              )}
              {prevDecision?.rowCount &&
              prevDecision?.rowCount?.length > 0 &&
              prevDecision?.columnCount &&
              prevDecision?.columnCount?.length > 0 ? (
                <span className="font-code text-sm text-neutral-black">
                  {`[`}
                  {rowCountHighlight.prevHighlighted} Rows *{" "}
                  {columnCountHighlight.prevHighlighted} Columns{`]`}
                </span>
              ) : prevDecision?.rowCount &&
                prevDecision?.rowCount?.length > 0 ? (
                <span className="font-code text-sm text-neutral-black">
                  [{rowCountHighlight.prevHighlighted} Rows]
                </span>
              ) : (
                prevDecision?.columnCount &&
                prevDecision?.columnCount?.length > 0 && (
                  <span className="font-code text-sm text-neutral-black">
                    [{columnCountHighlight.prevHighlighted} Columns]
                  </span>
                )
              )}
              {prevDecision.isEdited && (
                <span className="text-neutral-black  mt-1"></span>
              )}
            </div>
          ) : (
            <span className="text-neutral-500 m-auto">
              DecisionTable not present
            </span>
          )}
        </div>

        <div className="flex flex-col w-[50%] px-4 py-3 gap-2 left-0">
          {currDecision ? (
            <div className="text-neutral-500 flex-col font-b2 flex flex-wrap">
              <span>
                {currDecision?.default && (
                  <span className="mr-2">
                    Default value:{" "}
                    <span className="font-code text-sm text-primary-900">
                      {defaultHighlight.currHighlighted}
                    </span>
                  </span>
                )}
                {currDecision?.rowCount &&
                currDecision?.rowCount?.length > 0 &&
                currDecision?.columnCount &&
                currDecision?.columnCount?.length > 0 ? (
                  <span className="font-code text-sm text-neutral-black">
                    {`[`}
                    {rowCountHighlight.currHighlighted} Rows *{" "}
                    {columnCountHighlight.currHighlighted} Columns{`]`}
                  </span>
                ) : currDecision?.rowCount &&
                  currDecision?.rowCount?.length > 0 ? (
                  <span className="font-code text-sm text-neutral-black">
                    [{rowCountHighlight.currHighlighted} Rows]
                  </span>
                ) : (
                  currDecision?.columnCount &&
                  currDecision?.columnCount?.length > 0 && (
                    <span className="font-code text-sm text-neutral-black">
                      [{columnCountHighlight.currHighlighted} Columns]
                    </span>
                  )
                )}
              </span>
              {currDecision?.isEdited && (
                <div className="flex justify-between mt-2">
                  <span className="text-neutral-black">
                    Table inputs are modified
                  </span>
                  <button
                    className="text-primary-900 hover:text-neutral-black font-b2-medium"
                    onClick={() =>
                      navigate(
                        `/workflow/${workflowId}/${modelId}/modelset-table/${currDecision.id}`
                      )
                    }
                  >
                    View Table
                  </button>
                </div>
              )}
            </div>
          ) : (
            <span className="text-neutral-500 m-auto">
              DecisionTable is deleted
            </span>
          )}
        </div>
      </div>
    </div>
  );
};
