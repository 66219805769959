import { clsx } from "clsx";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import Tooltip from "src/components/Tooltip";
import { analyticsInstance } from "src/config/event-analytics";
import { PolicyStudioActions } from "src/constants/EventAnalytics";
import { useWorkflowContext } from "src/screens/workflow/WorkflowContext";
import { ReactComponent as CheckIcon } from "@assets/notificationIcons/check-circle-broken.svg";
import {
  getCustomInputsQuery,
  useGetCustomInputs,
  useSaveCustomInputs,
} from "@screens/create-policy/queries";
import { getErrors, getWorkflowKeywordsQuery } from "@screens/workflow/queries";
import Button from "@components/Button";
import QueryWrapper from "@components/QueryWrapper";
import Shimmer from "@components/Shimmer";
import { useQueryClient } from "@tanstack/react-query";
import {
  generateAndDownloadFile,
  getNetworkErrorText,
  notify,
} from "@utils/utils";
import { InputForm } from "./InputForm";
import { FormType } from "./InputParameters.types";

const InputParameters = () => {
  const queryClient = useQueryClient();
  const { workflowId } = useParams();
  const inputListQuery = useGetCustomInputs(workflowId || "", "workflow");
  const { isWorkflowEditable, workflow } = useWorkflowContext();

  const isNullableEnabled = !!workflow?.settings.isNullableInputsAllowed;

  const saveCustomInputMutation = useSaveCustomInputs();

  const form = useForm<FormType>({
    values: {
      inputs: inputListQuery.data?.data as FormType["inputs"],
    },
    shouldUnregister: true,
  });

  const onSave = form.handleSubmit((data) => {
    if (!workflowId) {
      return;
    }

    const totalCount = {
      text: 0,
      number: 0,
      boolean: 0,
    };

    saveCustomInputMutation.mutate(
      isNullableEnabled
        ? {
            inputs: data.inputs.map((item) => {
              if (item.dataType === "text") {
                totalCount.text += 1;
              }
              if (item.dataType === "number") {
                totalCount.number += 1;
              }
              if (item.dataType === "boolean") {
                totalCount.boolean += 1;
              }
              return {
                ...item,
                defaultInput:
                  isNullableEnabled &&
                  item.defaultInput === "" &&
                  item.isNullable
                    ? null
                    : item.defaultInput,
              };
            }),
            policyID: workflowId,
            policyType: "workflow",
          }
        : {
            inputs: data.inputs.map((item) => {
              if (item.dataType === "text") {
                totalCount.text += 1;
              }
              if (item.dataType === "number") {
                totalCount.number += 1;
              }
              if (item.dataType === "boolean") {
                totalCount.boolean += 1;
              }
              return {
                ...item,
                isNullable: !!item.defaultInput,
              };
            }),
            policyID: workflowId,
            policyType: "workflow",
          },
      {
        onSuccess: () => {
          analyticsInstance.triggerAnalytics(
            PolicyStudioActions.SIDEBAR_INPUT_PARAMETERS_DOWNLOAD_SAVE,
            {
              bucket_name: workflow?.policyName ?? "",
              version: workflow?.policyVersion ?? "",
              ...totalCount,
            }
          );
          queryClient.invalidateQueries(getCustomInputsQuery());
          queryClient.invalidateQueries(getWorkflowKeywordsQuery());
          queryClient.invalidateQueries(getErrors(workflowId));
          notify({
            title: "Saved",
            text: "Workflow inputs updated",
            type: "success",
          });
        },
        onError: (err) => {
          notify({
            title: "Error",
            text: getNetworkErrorText(err),
          });
        },
      }
    );
  });

  const exportInputs = () => {
    if (!inputListQuery.isSuccess) return;
    if (workflow?.settings.isNullableInputsAllowed)
      generateAndDownloadFile(
        `name,dataType,nullable,defaultInput
${inputListQuery.data.data
  .map(
    (item) =>
      `${item.name},${item.dataType},${item.isNullable},${item.defaultInput}`
  )
  .join("\n")}`,
        `${workflow?.name}_inputs.csv`
      );
    else
      generateAndDownloadFile(
        `name,dataType,defaultInput
${inputListQuery.data.data
  .map((item) => `${item.name},${item.dataType},${item.defaultInput}`)
  .join("\n")}`,
        `${workflow?.name}_inputs.csv`
      );

    analyticsInstance.triggerAnalytics(
      PolicyStudioActions.SIDEBAR_INPUT_PARAMETERS_EXPORT,
      {
        bucket_name: workflow?.policyName ?? "",
        version: workflow?.policyVersion ?? "",
      }
    );
  };
  const handleMarkAllNullable = () => {
    if (form.getValues(`inputs`)?.length > 0) {
      form.getValues(`inputs`).forEach((_, index) => {
        form.setValue(`inputs.${index}.isNullable`, true);
      });

      analyticsInstance.triggerAnalytics(
        PolicyStudioActions.SIDEBAR_INPUT_PARAMETERS_MARK_ALL_AS_NULLABLE,
        {
          bucket_name: workflow?.policyName ?? "",
          version: workflow?.policyVersion ?? "",
        }
      );
    }
  };

  return (
    <>
      <QueryWrapper query={inputListQuery} loader={<></>}>
        {() => (
          <div
            className={clsx(
              "absolute top-11 items-center flex gap-2",
              isNullableEnabled ? "left-[350px]" : "left-[370px]"
            )}
          >
            {isNullableEnabled && (
              <div
                className="w-6 h-6 ml-4 flex justify-center items-center rounded-md hover:bg-neutral-25 cursor-pointer"
                onClick={handleMarkAllNullable}
              >
                <Tooltip
                  bottom
                  left
                  contentClassName="flex items-center"
                  content={
                    <CheckIcon
                      className="w-4 h-4"
                      onClick={handleMarkAllNullable}
                    />
                  }
                  tooltipContent={
                    <p className="font-b2 text-neutral-black">
                      Mark all as nullable
                    </p>
                  }
                />
              </div>
            )}
            <Button
              onClick={() => exportInputs()}
              variant="outline"
              className="!h-6"
            >
              Export
            </Button>
          </div>
        )}
      </QueryWrapper>
      <QueryWrapper
        query={inputListQuery}
        loader={<Shimmer className="w-[calc(100%-32px)] mx-4 h-[150px] mt-4" />}
      >
        {() => (
          <div className="flex flex-col h-full pb-10 overflow-auto">
            <InputForm form={form} />
          </div>
        )}
      </QueryWrapper>
      <div className="absolute mt-auto bottom-0 w-[479px] flex justify-end py-2 pr-2 bg-neutral-0 border-t border-neutral-100">
        <Button
          onClick={onSave}
          disabled={saveCustomInputMutation.isPending || !isWorkflowEditable}
        >
          Save
        </Button>
      </div>
    </>
  );
};

export default InputParameters;
