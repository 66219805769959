export function transformResponseToColumnArray(response: {
  default: string;
  headers: string[];
  rows: Array<{
    columns: { name: string; value: string }[];
    output: string;
  }>;
}) {
  let rows:{ column: string[], output: string }[] = [{ column: [], output: "" }];
  if (response.rows.length > 0) {
    rows = response.rows.map((row) => ({
      output: row.output,
      column: response.headers.map((header) => {
        const columnValue =
          row.columns.find((col) => col.name === header)?.value || "";
        return columnValue;
      }),
    }));
  }

  return {
    default: response.default,
    headers: response.headers.map((header) => ({ name: header })),
    rows,
  };
}

export function transformColumnArrayToRequest(columnArray: {
  default: string;
  headers: { name: string }[];
  rows: Array<{
    column: string[];
    output: string;
  }>;
}) {
  const { headers } = columnArray;
  const rows = columnArray.rows.map((row) => {
    const columns = headers.map((header, index) => ({
      name: header.name,
      value: row.column[index] !== undefined ? row.column[index] : "",
    }));

    return { columns, output: row.output };
  });

  return {
    default: columnArray.default,
    headers: headers.map(({ name }) => name),
    rows,
  };
}
